import React from 'react'

export default class Divider extends React.PureComponent {
  render() {
    return <span style={{
      position: 'relative',
      width: '100%',
      height: 1,
      backgroundColor: '#f4f4f4'
    }} />
  }
}