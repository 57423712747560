import React from 'react'
import TalkingPoints from 'components/TalkingPoints'

import talkingPointsJSONData from 'data/talkingpoints.json'

export default class ConversationView extends React.PureComponent{
  render() {
    return (
      talkingPointsJSONData.map((item, index) => <TalkingPoints key={index} index={index} item={item} />)
    )
  }
}