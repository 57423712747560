import React from 'react'
import { NotificationImportant } from '@material-ui/icons' 
import { alertType } from './enums/alertType'
import { alertMessage } from './enums/alertMessage'

import './Alert.scss'

export default class Alert extends React.Component {
  state = {
    isDisplay: false,
    isReloading: false,
    message: null,
    type: null
  }

  componentDidMount = () => {
    this._watchNewContentAvailable()
  }

  _watchNewContentAvailable = () => {
    window.addEventListener("newContentAvailable", () => {
      
      this.setState({
        isDisplay: true,
        isReloading: alertMessage.ServiceUpdate.IsReloading,
        message: alertMessage.ServiceUpdate.Message,
        type: alertMessage.ServiceUpdate.Type,
      })
    })
  }

  _handlePageReload = () => {
    // When the user asks to refresh the UI, we'll need to reload the window
    var preventDevToolsReloadLoop;
    navigator.serviceWorker.addEventListener("controllerchange", function() {
        // Ensure refresh is only called once.
        // This works around a bug in "force update on reload".
        if (preventDevToolsReloadLoop) return;
        preventDevToolsReloadLoop = true;
        window.location.reload();
    });

    // Send ServiceWorker message to skipWaiting
    navigator.serviceWorker.ready.then(registration => {
      registration.waiting &&
      registration.waiting.postMessage("skipWaiting")
    })
  }

  render() {
    return (
      (this.state.isDisplay)?
        <div className={`
          alert 
          ${(this.state.type === alertType.Info)? 'alert--info' : ''}
          ${(this.state.type === alertType.Warning)? 'alert--warning' : ''}
          ${(this.state.type === alertType.Error)? 'alert--error' : ''}`}>

          <NotificationImportant className="alert__icon" />
          <div className="alert__message">
            {this.state.message}
            {(this.state.isReloading)? <span className="alert__action" onClick={this._handlePageReload}>Click to Refresh</span> : ''}
          </div>
        </div>
      : null
    )
  }
}