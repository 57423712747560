import React from 'react'
import UserBanner from 'components/UserBanner'
import UserNavigation from 'components/UserNavigation'
import ConversationView from 'views/ConversationView'
import AssistantView from 'views/AssistantView'
import AssistantBanner from 'components/AssistantBanner'
import { navType } from 'utils/enums';

export default class UserScreen extends React.Component{
  state = {
    currentTab: 0,
    wordCloudData: null,
    isRecording: false,
    isAssistantUnlocked: false
  }

  handleSelectNav = (event, newValue) => this.setState({ currentTab: newValue })

  handleRecorderStatus = () => this.setState({ isRecording: !this.state.isRecording })

  handleAssistantUnlock = (val) => this.setState({ isAssistantUnlocked: val })

  render() {
    const { history } = this.props
    const { user } = this.props.location.state
    
    return (
      <>
        <UserBanner
          firstname={user.firstname}
          lastname={user.lastname}
          job={user.job}
          avatar={user.avatar}
          history={history} />
        <UserNavigation 
          currentTab={this.state.currentTab}
          selectTabFunction={this.handleSelectNav} />
        <div style={{ overflowY: 'scroll', flex: 2 }}>
          {(this.state.currentTab === navType.Conversation)? 
            <ConversationView /> 
            : <AssistantView 
                isRecording={this.state.isRecording} 
                handleRecordingStatus={this.handleRecorderStatus}
                handleAssistantUnlock={this.handleAssistantUnlock}
                isAssistantUnlocked={this.state.isAssistantUnlocked}
                data={this.state.wordCloudData} />}
        </div>
        {(this.state.isAssistantUnlocked)? <AssistantBanner handleRecorderStatus={this.handleRecorderStatus} isRecording={this.state.isRecording} /> : null}
      </>
    )
  }
}