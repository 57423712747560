import React from 'react'
import ReactWordcloud from 'react-wordcloud'
import ReactLoading from 'react-loading'

import wordsData from 'data/wordcloud'
import { blue } from 'utils/color'

export default class WordCloud extends React.Component {
  state = {
    isLoading: true
  }

  
  componentDidMount = () => {
    setTimeout(() => this.setState({ isLoading: false }), 1200)
  }

  render() {
    const options = {
      colors: [blue[100], blue[300], blue[700]],
      enableTooltip: false,
      deterministic: false,
      fontFamily: 'Source Sans Pro',
      fontSizes: [5, 60],
      fontStyle: 'normal',
      fontWeight: 'normal',
      padding: 1,
      rotations: 1,
      rotationAngles: [0],
      scale: 'sqrt',
      spiral: 'archimedean',
      enableTransition: false,
      transitionDuration: 0,
    }

    // return (this.state.isLoading)? <ReactLoading type="bubbles" color={blue[300]} /> : <ReactWordcloud options={options} words={wordsData}/>
    return (this.state.isLoading)? <ReactLoading type="bubbles" color={blue[300]} /> : 'This features is not yet implemented'
  }
}