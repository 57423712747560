import React from 'react';
import PropTypes from 'prop-types';
import './HiddenSelect.scss'

export default class HiddenSelect extends React.PureComponent {
  static props = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.isRequired,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  }

  render() {
    return (
      <label 
        className="hiddenSelect"
        htmlFor={this.props.name}>
        {this.props.children}
        <select
          id={this.props.name} 
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          className="hiddenSelect__select">
          {this.props.data.map((item, index) => <option key={index} value={index}>{item}</option>)}
        </select>
      </label>
    )
  }
}