import React from 'react'
import PropTypes from 'prop-types'
import Recorder from 'components/Recorder'
import WordCloud from 'components/WordCloud'
import placeholder from './placeholder.png'

import './AssistantView.scss'

export default class AssistantView extends React.Component {
  static props = {
    data: PropTypes.isRequired,
    handleRecordingStatus: PropTypes.func.isRequired,
    handleAssistantUnlock: PropTypes.func.isRequired,
    isAssistantUnlocked: PropTypes.bool.isRequired,
    isRecording: PropTypes.bool.isRequired
  }

  render() {
    return (
      <div className={`
        assistantView
        ${(this.props.isAssistantUnlocked)? ' assistantView--active' : ''}`}>

        {(this.props.isAssistantUnlocked)? 
          <div className="assistantView__content">
            <WordCloud />
          </div>
        : <div className="assistantView__intro">
            <img className="assistantView__placeholder" src={placeholder} alt="word cloud" />
            <span>The vocal assistant will help you to not forget about topics talked during your one on one.</span>
          </div> 
        }

        <div className="assistantView__actions">
          <Recorder 
            isRecording={this.props.isRecording} 
            handleRecordingStatus={this.props.handleRecordingStatus}
            isUnlocked={this.props.handleAssistantUnlock} />
        </div>
      </div>
    )
  }
}