import React from 'react'
import PropTypes from 'prop-types'
import { Pause, Mic } from '@material-ui/icons'

import './AssistantBanner.scss'

export default class AssistantBanner extends React.PureComponent{
  static props = {
    handleRecorderStatus: PropTypes.func.isRequired,
    isRecording: PropTypes.bool.isRequired,
  }

  render() {
    return (
      <div className="assistantBanner">
        <div className="assistantBanner__content">
          <span className="assistantBanner__status">{(this.props.isRecording)? 'Assistant listening...' : 'Assistant paused'}</span>
          <div className="assistantBanner__action" onClick={() => this.props.handleRecorderStatus()}>
            {(this.props.isRecording)? 
              <>Pause <Pause /></> 
              : <>Start <Mic /></>
            }
          </div>
        </div>

        {(this.props.isRecording)? <div className="assistantBanner__ripple"></div> : null}
      </div>
    )
  }
}