import React from 'react'
import placeholderTeams from 'data/placeholderTeams'
import placeholderUser from 'data/placeholderUser'
import List from 'components/List'
import ListItem from 'components/ListItem'
import HiddenSelect from 'components/HiddenSelect'
import Divider from 'components/Divider'

export default class HomeScreen extends React.PureComponent {
  static navigationOptions = {
    title: 'Home',
  }

  state = {
    teams: placeholderTeams(),
    users: placeholderUser(),
    selectedTeam: 0,
    isSelectTeamVisible: false
  }

  _handleTeamChange = (event) => {
    const i = event.target.value
    
    if(this.state.selectedTeam !== i) {
      this.setState({ selectedTeam: i, users: placeholderUser(i), isSelectTeamVisible: !this.state.isSelectTeamVisible })
    }
  }

  _renderTeam = () => {
    let data = []
    this.state.teams.map((item) => data.push(item.name))

    return(
      <HiddenSelect 
        name="team"
        data={data}
        value={this.state.selectedTeam}
        onChange={this._handleTeamChange}>
          
        <ListItem
          swap
          title={this.state.teams[this.state.selectedTeam].name}
          titleClassName='listItem__title--bold'
          // subtitle={'Select a different team'}
          leftAvatar={{
            source: this.state.teams[this.state.selectedTeam].logo,
            width: 60,
            height: 60,
            borderRadius: 10
          }} />
      </HiddenSelect>
    )
  }

  _renderUserList = ({ item, index }) => (
    <ListItem
      chevron
      key={index}
      title={`${item.firstname} ${item.lastname}`}
      subtitle={item.job}
      leftAvatar={{ 
        source: item.avatar,
        width: 40,
        height: 40,
        borderRadius: 5
      }}
      onClick={() => this.props.history.push(`/user/${index}`, {user: item})} />
  )

  render() {
    return (
      <>
        {this._renderTeam()}
        <Divider />
        <List
          data={this.state.users}
          render={this._renderUserList} />
      </>
    )
  }
}