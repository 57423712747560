import React from 'react'
import PropTypes from 'prop-types'
import { Notes, GraphicEq } from '@material-ui/icons'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { navType } from 'utils/enums'

import './UserNavigation.scss'

export default class UserNavigation extends React.PureComponent {
  static props = {
    currentTab: PropTypes.number.isRequired,
    selectTabFunction: PropTypes.func.isRequired
  }

  a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    }
  }

  render() {
    return (
      <Tabs
        className="nav"
        value={this.props.currentTab}
        onChange={this.props.selectTabFunction}
        variant="fullWidth">
        <Tab label="Conversation" icon={<Notes />} {...this.a11yProps(navType.Conversation)} />
        <Tab label="Assistant" icon={<GraphicEq />} {...this.a11yProps(navType.Assistant)} />
      </Tabs>
    )
  }
}