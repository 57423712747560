import React from 'react';
import PropTypes from 'prop-types';
import './List.scss'

export default class List extends React.PureComponent {
  static props = {
    data: PropTypes.object.isRequired,
    render: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className="list">
        {this.props.data.map((item, index) => {
          return this.props.render({ item, index })
        })}
      </div>
    )
  }
}