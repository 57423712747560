export default function placeholderUser() {
  let list = []
  const roles = [
    "Designer", "Developper", "Lutin",
    "Marketer", "AI Scientist",
  ]
  const names = [
    "Scarlet", "Wallace", "Sofia", "Jadon", "Catrina", 
    "Corrina", "Areeba", "Vera", "Saul", "Velma",
  ]
  
  for (let i = 0; i < 20; i++) {
    let randomRole = Math.floor(Math.random() * (roles.length))
    let randomFirstName = Math.floor(Math.random() * (names.length))
    let randomLastName = Math.floor(Math.random() * (names.length))

    list.push({
        avatar: `https://picsum.photos/200?random${i}`,
        firstname: names[randomFirstName],
        lastname: names[randomLastName],
        job: roles[randomRole]
      }
    )
  }

  return list
}