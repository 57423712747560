import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { AddCircleOutlined, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import Checkbox from '@material-ui/core/Checkbox'
import Textarea from 'react-textarea-autosize'
import { talkingType } from 'utils/enums'

import './TalkingPoints.scss'

export default class TalkingPoints extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
      by: PropTypes.number.isRequired,
      summary: PropTypes.string,
      actionItems: PropTypes.arrayOf(PropTypes.string)
    })
  }

  state = {
    summary: this.props.item.summary,
    actionItems: this.props.item.actionItems,
    isChecked: false,
    isDetailsVisible: false
  }

  _handleSummaryChange = (event) => this.setState({summary: event.target.value})

  _handleActionItemsChange = i => event => {
    const value = event.target.value
    const obj = this.state.actionItems.map((item, index) => {
      return (i !== index)? item : value
    })

    this.setState({ actionItems: obj })
  }

  _handleAddNew = (type) =>
    (type === talkingType.Summary)? 
      this.setState({ summary: " " }, () => this[`summary`]._ref.focus())
    : this.setState(prevState => {
      const total = prevState.actionItems.length
      prevState.actionItems[total] = ""
      return { actionItems: prevState.actionItems }
    }, () => this[`actionItems${this.state.actionItems.length -1}`]._ref.focus())

  _renderItemContent = () => (
    <div>
      <div className="talkingPoints__edit">
        <span className="talkingPoints__subtitle">Summary</span>
      </div>
      {(this.state.summary)? 
          (<div>
            <Textarea
              ref={input => this[`summary`] = input}
              className="talkingPoints__summary"
              onChange={this._handleSummaryChange.bind(this)}
              value={this.state.summary} />
          </div>)
      : this._renderAddMore(talkingType.Summary)}
    </div>
  )

  _renderActionItems = () => (
    <div>
      <div className="talkingPoints__edit">
        <span className="talkingPoints__subtitle">
          Action Items
        </span>
      </div>

      {(!_.isEmpty(this.state.actionItems))?
        this.state.actionItems.map((item, i) => (
          <div key={i} className="talkingPoints__list">
            <i className="talkingPoints__bullet">{'\u2022'}</i>
            <Textarea 
              ref={input => {this[`actionItems${i}`] = input}}
              className="talkingPoints__listItem" 
              onChange={this._handleActionItemsChange(i).bind(this)}
              value={this.state.actionItems[i]} />
          </div>
        ))
      : null }

      {this._renderAddMore(talkingType.Action)}
    </div>
  )

  _renderAddMore = (type) => (
    <div 
      className="talkingPoints__edit"
      onClick={() => this._handleAddNew(type)}>
      <span className="talkingPoints__more">
        <AddCircleOutlined fontSize={'inherit'} /> &nbsp;Add {(type === talkingType.Summary)? 'Summary' : 'Action Item'}
      </span>
    </div>
  )

  render() {
    return (
      <>
      <div className={`talkingPoints ${(this.state.isDetailsVisible)? ' talkingPoints--active' : ''}`}>
        <Checkbox
          className="talkingPoints__checkbox"
          checked={this.state.isChecked}
          onChange={() => this.setState({ isChecked: !this.state.isChecked })} />
        
        <div 
          className="talkingPoints__header"
          onClick={() => this.setState({ isDetailsVisible: !this.state.isDetailsVisible })}>
            <span className="talkingPoints__username">User Name</span>
            <span>{this.props.item.title}</span>
        </div>

        <div className="talkingPoints__icon" onClick={() => this.setState({ isDetailsVisible: !this.state.isDetailsVisible })}>
          {(this.state.isDetailsVisible)? <KeyboardArrowDown/> : <KeyboardArrowRight/>}
        </div>
      </div>
      {(this.state.isDetailsVisible)? 
        <div className="talkingPoints__content">
          {this._renderItemContent()}
          {this._renderActionItems()}
        </div> 
      : null}
    </>
    )
  }
}
