import React from 'react'
import { ReactRouterGlobalHistory } from 'react-router-global-history'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import HomeScreen from 'screens/home'
import UserScreen from 'screens/user'
import Alert from 'components/Alert'
import InstallView from 'views/InstallView'

import 'normalize.css'

export default class App extends React.PureComponent {
  state = {
    deferredPrompt: null,
    isInstallerVisible: false
  }

  componentDidMount = () => {
    this._watchBeforeInstallPrompt()
  }

  _watchBeforeInstallPrompt = () => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();

      this.setState({ 
        deferredPrompt: e,
        isInstallerVisible: this._isInstallerVisible()
      })
    })
  }

  _isInstallerVisible = () => !window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === false

  handleInstallerVisible = (status) => this.setState({ isInstallerVisible: status })

  render() {
    return (
      <Router>
        <div style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column"
        }}>
          <Alert />
          {!this.state.isInstallerVisible ? 
            <>
              <ReactRouterGlobalHistory />
              <Route exact path="/" component={HomeScreen} />
              <Route path="/user/:id" component={UserScreen} />
            </> 
          : <InstallView 
              handleInstallerVisible={this.handleInstallerVisible}
              deferredPrompt={this.state.deferredPrompt}
              isVisible={this.state.isInstallerVisible} /> 
          }
        </div>
      </Router>
    )
  }
}
