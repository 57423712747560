import React from 'react'
import PropTypes from 'prop-types'
import { KeyboardArrowRight, SwapVert } from '@material-ui/icons'
import './ListItem.scss'

export default class ListItem extends React.PureComponent {
  static props = {
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.object,
    subtitle: PropTypes.string,
    subtitleClassName: PropTypes.object,
    leftAvatar: PropTypes.shape({
      source: PropTypes.string.isRequired,
      width: PropTypes.number,
      height: PropTypes.number,
      borderRadius: PropTypes.number,
      isRound: PropTypes.bool
    }),
    className: PropTypes.string,
    chevron: PropTypes.bool,
    onClick: PropTypes.func
  }

  render() {
    return (
      <div className={`listItem 
        ${(this.props.className)? this.props.className : ''}`}
        onClick={(this.props.onClick)? this.props.onClick : null} >
        {(this.props.leftAvatar)? (
          <img 
            src={this.props.leftAvatar.source} 
            alt={this.props.title} 
            style={{
              width: this.props.leftAvatar.width,
              height: this.props.leftAvatar.height,
              borderRadius: (this.props.leftAvatar.isRound)? '100%': (this.props.leftAvatar.borderRadius) ? this.props.leftAvatar.borderRadius : 0
            }}/>
        ) : null}

        <div className="listItem__content">
          {(this.props.title)? 
            <span 
              className={`
                listItem__title 
                ${(this.props.titleClassName)?  this.props.titleClassName : ''}`}>
              {this.props.title}
            </span> 
          : null}

          {(this.props.subtitle)? 
            <span 
              className={`
              listItem__subtitle 
              ${(this.props.subtitleClassName)?  this.props.subtitleClassName : ''}`}>
              {this.props.subtitle}
            </span> 
          : null}
        </div>
        {(this.props.swap)? <SwapVert className="listItem__swap listItem__chevron--right" /> : null}
        {(this.props.chevron)? <KeyboardArrowRight className="listItem__chevron listItem__chevron--right" /> : null}
      </div>
    )
  }
}