import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import { Mic, Stop } from '@material-ui/icons'

import './Recorder.scss'

export let global = {
  leftchannel: [],
  rightchannel: [],
  recordingLength: 0,
  source: null,
  processor: null,
  context: null
}

export default class Recorder extends React.Component {
  static props = {
    handleRecordingStatus: PropTypes.func.isRequired,
    isRecording: PropTypes.bool.isRequired,
    isUnlocked: PropTypes.func.isRequired
  }
  
  _flattenArray = (channelBuffer, recordingLength) => {
    const result = new Float32Array(recordingLength);
    let offset = 0;
    for (let i = 0; i < channelBuffer.length; i++) {
      const buffer = channelBuffer[i];
      result.set(buffer, offset);
      offset += buffer.length;
    }
    return result;
  }

  _interleave = (leftChannel, rightChannel) => {
    const length = leftChannel.length + rightChannel.length;
    const result = new Float32Array(length);
    let inputIndex = 0;
    for (let index = 0; index < length;) {
      result[index++] = leftChannel[inputIndex];
      result[index++] = rightChannel[inputIndex];
      inputIndex++;
    }
    return result;
  }

  _writeUTFBytes = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  }

  _handleRecordAudio = () => {
    navigator.getUserMedia = (navigator.getUserMedia ||
                              navigator.webkitGetUserMedia ||
                              navigator.mozGetUserMedia ||
                              navigator.msGetUserMedia)

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(this._handleRecordSuccess)
      .catch(err => console.log(`Error: ${err}`))
  }

  _handleRecordSuccess = (stream) => {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    const bufferSize = 2048
    global.context = new AudioContext()
    global.source = global.context.createMediaStreamSource(stream)
    global.processor = global.context.createScriptProcessor(bufferSize, 2, 2)

    global.source.connect(global.processor)
    global.processor.connect(global.context.destination)

    global.processor.onaudioprocess = (e) => {
      global.leftchannel.push(new Float32Array(e.inputBuffer.getChannelData(0)))
      global.rightchannel.push(new Float32Array(e.inputBuffer.getChannelData(1)))
      global.recordingLength += bufferSize
    }

    // Tell parent that word cloud is ready to be created
    // and should change the interface display
    this.props.isUnlocked(true)
    
    this.props.handleRecordingStatus()
  }

  _handleStopRecord = () => {
    // stop recording
    global.source.disconnect(global.processor)
    global.processor.disconnect(global.context.destination)
    this.props.handleRecordingStatus()

    const sampleRate = 44100
    // flat the left and right channels down
    const leftBuffer = this._flattenArray(global.leftchannel, global.recordingLength)
    const rightBuffer = this._flattenArray(global.rightchannel, global.recordingLength)
    const interleaved = this._interleave(leftBuffer, rightBuffer)

    // create our wav file
    const buffer = new ArrayBuffer(44 + interleaved.length * 2)
    const view = new DataView(buffer)

    // RIFF chunk descriptor
    this._writeUTFBytes(view, 0, 'RIFF');
    view.setUint32(4, 44 + interleaved.length * 2, true);
    this._writeUTFBytes(view, 8, 'WAVE');
    // FMT sub-chunk
    this._writeUTFBytes(view, 12, 'fmt ');
    view.setUint32(16, 16, true); // chunkSize
    view.setUint16(20, 1, true); // wFormatTag
    view.setUint16(22, 2, true); // wChannels: stereo (2 channels)
    view.setUint32(24, sampleRate, true); // dwSamplesPerSec
    view.setUint32(28, sampleRate * 4, true); // dwAvgBytesPerSec
    view.setUint16(32, 4, true); // wBlockAlign
    view.setUint16(34, 16, true); // wBitsPerSample
    // data sub-chunk
    this._writeUTFBytes(view, 36, 'data');
    view.setUint32(40, interleaved.length * 2, true)

    // write the PCM samples
    let index = 44
    let volume = 1
    for (let i = 0; i < interleaved.length; i++) {
      view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
      index += 2
    }

    const blob = new Blob([view], { type: 'audio/wav' })
    const url = window.URL.createObjectURL(blob)

    return this._handleDownloadFile(url)
  }

  _handleDownloadFile = (url) => {
    let a = document.createElement("a")
    a.href = url;
    a.download = url;
    a.click()

    return window.URL.revokeObjectURL(url)
  }

  _renderIcon = () => (this.props.isRecording)? <Stop className="recorder__recordIcon" /> : <Mic className="recorder__recordIcon" />

  _renderCallToAction = () => (this.props.isRecording)? '' : <span className="recorder__cta">Start Assistant</span>

  render() {
    return (
      <div className="recorder">
        <Fab 
          variant={(this.props.isRecording)? 'round' : 'extended'} 
          aria-label="record" 
          className={`recorder__button ${(this.props.isRecording)? 'recorder__button--isRecording' : ''}`}
          onClick={(this.props.isRecording)? this._handleStopRecord : this._handleRecordAudio}>
          {this._renderIcon()}
          {this._renderCallToAction()}
        </Fab>
        <span className="recorder__disclamer">No audio will be keept or shared.</span>
      </div>
    )
  }
}