import React from 'react'
import PropTypes from 'prop-types'
import Image from './talking-image.png'
import Logo from './logo.svg'
import Cloud from './cloud.svg'
import Button from '@material-ui/core/Button'
import './InstallView.scss'

export default class InstallView extends React.PureComponent {
  static props = {
    handleInstallerVisible: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    deferredPrompt: PropTypes.object.isRequired
  }

  _handleInstallApp = () => {
    this.props.deferredPrompt.prompt()
    this.props.deferredPrompt.userChoice
    .then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      
      this._handleSkipInstall()
    })
  }

  _handleSkipInstall = () => {
    this.props.handleInstallerVisible(false)
  }

  render() {
    return (
      (this.props.isVisible)?
        <div className="installView">
          <img className="installView__logo" src={Logo} alt="Candor Logo" />

          <div className="installView__cloud">
          <img className="installView__image installView__image--cloud" src={Cloud} alt="" />
            <img className="installView__image" src={Image} alt="People Talking" />
          </div>
          

          <div className="installView__wrap">
            <div className="installView__content">
              For optimal performance, we recommend to install the app on your phone. It will be added to your home&nbsp;screen.
            </div>

            <div className="installView__actions">
              <Button 
                aria-label="install" 
                className={`installView__button`}
                onClick={this._handleInstallApp}>
                Install the app
              </Button>

              <span onClick={this._handleSkipInstall} className="installView__skip">No thanks</span>
            </div>
          </div>
        </div>
      : null
    )
  }
}