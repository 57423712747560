export default function placeholderTeams() {
  let list = []
  const names = [
    { name: "The Motionless Buffalos"},
    { name: "The Racial Elephants"},
    { name: "The Embarrassed Squirrels"},
    { name: "The Intelligent Shrews"},
    { name: "The Functional Gazelles"},
    { name: "The Laughable Antelopes"},
    { name: "The Frequent Wolves"},
    { name: "The Flashy Turtles"},
    { name: "The Zany Chimpanzees"},
    { name: "The Modern Koalas"},
    { name: "The Nice Sheep"},
    { name: "The Measly Eagles"},
    { name: "The Awake Dogfishes"}
  ]
  
  names.map((team, i) => list.push({
    logo: `https://picsum.photos/200?random${i}`,
    name: team.name
  }))

  return list
}