import React from 'react'
import PropTypes from 'prop-types'
import { KeyboardArrowLeft } from '@material-ui/icons'
import ListItem from 'components/ListItem'
import Divider from 'components/Divider'
import './UserBanner.scss'

export default class UserBanner extends React.PureComponent {
  static props = {
    history: PropTypes.object.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    avatar: PropTypes.string
  }

  render() {
    return (
      <>
        <div className="userBanner">
          <KeyboardArrowLeft
            className="userBanner__back"
            onClick={() => this.props.history.push(`/`)}  />
          <ListItem
            title={`${this.props.firstname} ${this.props.lastname}`}
            subtitle={this.props.job}
            leftAvatar={{ 
              source: this.props.avatar,
              width: 60,
              height: 60,
              borderRadius: 10
            }} />
        </div>
        <Divider />
      </>
    )
  }
}